import React from "react";

const DefineTitle = (props) => {
    return (
        <div>
            <div className="font-bold text-xl text-center py-3 mb-5 border-b border-black w-full">
                {props.title}
            
            </div>
            {props.children}
        </div>
    )
}

export default DefineTitle;