import React from "react";
import { Link } from "react-router-dom";

const App = (props) => {
    return (
        <div className="flex flex-col h-screen justify-center items-center font-sans">
            <div className="text-6xl font-extrabold italic pt-1">
                <Link to="/"> CTMUN Parley 2.0</Link>
            </div>
            {props.children}
            <div className="absolute text-xs text-center bottom-3 inset-x-0">
                Copyright &copy; {new Date().getFullYear()} <a href="https://www.ctmun.org">Central Texas Model United Nations</a>
            </div>
            
        </div>
    );
}

export default App;