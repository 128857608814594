import React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import TimerInput from "../../Components/TimerInput/TimerInput";
import DefineTitle from "../../Components/DefineTitle";
import SubmitButton from "../../Components/SubmitButton";
import CaucusLink from "../../Components/CaucusLink";
import InputForm from "../../Components/InputForm";

const DefineMod = (props) => {


    const navigate = useNavigate();

    const convertTime = (minutes, seconds) => {
        return (parseInt(minutes) * 60) + parseInt(seconds);
    };

    const [validInput, setValidInput] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [state, setState] = useState({
        totalMinutes: 0,
        totalSeconds: 0,
        speakingMinutes: 0,
        speakingSeconds: 0,
        topic: "",
    });

    const checkValidInput = () => {
        let totalTime = convertTime(state.totalMinutes, state.totalSeconds);
        let speakingTime = convertTime(state.speakingMinutes, state.speakingSeconds);
        if(state.topic === "") {
            setValidInput(false);
            setErrorMessage("Please enter a topic");
            return;
        }
        if(totalTime <= 0 || speakingTime <= 0 ) {
            setValidInput(false);
            setErrorMessage("Times must be positive integers");
            return
        }
        
        if(totalTime % speakingTime !== 0) {
            setValidInput(false);
            setErrorMessage("Total time must be a multiple of the speaking time");
            return
        }
        setValidInput(true);
        setErrorMessage("")
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState(prevstate => ({
            ...prevstate,
            [name]: value
        }));
    };

    useEffect(() => {
        checkValidInput();
        //console.log(state);
    })

    const convertToPath = () => {
        //Convert total minutes and total seconds to total time in seconds
        //Convert speaking minutes and speaking seconds to speaking time in seconds
        const totalTime = convertTime(state.totalMinutes, state.totalSeconds);
        const speakingTime = convertTime(state.speakingMinutes, state.speakingSeconds);
        const topic = state.topic;

        if(validInput) {
            return `/mod/${totalTime}/${speakingTime}/${topic}`;
        } 

    }

    const handleClick = (e) => {
        e.preventDefault();
        if(validInput) {
            let path = convertToPath();
            navigate(path);
        }
    }

    return (
        <div className="app">
            <DefineTitle title="Moderated Caucus" />
            <InputForm>
                <div>
                    <label>Topic:</label>
                    <input className="ml-5 rounded-sm" type="text" name="topic" value={state.topic} onChange={handleChange} placeholder="Topic"/>
                </div>
                <div>
                    <TimerInput name="total" state={state} handleChange={handleChange}/>
                </div>
                <div>
                    <TimerInput name="speaking" state={state} handleChange={handleChange}/>
                </div>
                <div className="group relative">
                    {!validInput ? <div className="invisible group-hover:visible absolute 
                        -top-4 text-sm bg-gray-300 bg-opacity-70 rounded-lg px-2 py-1">
                            {errorMessage}
                    </div> : <></>}
                    <SubmitButton onClick={handleClick} validInput={validInput}/>
                </div>
            </InputForm>
            <CaucusLink />
        </div>
    );
};

export default DefineMod;