import React from "react";

const InputForm = (props) => {
    return (
        <form className="flex flex-col space-y-2 mx-8">
            {props.children}
        </form>
    )

}

export default InputForm;