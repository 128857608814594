import React from "react";
import { Link } from "react-router-dom";

const SelectCaucus = () => {
    return (
        <div className="app">
            <div className="text-center font-sans text-2xl py-6 border-b border-gray-400">Select Caucus Type</div>
            <Link to="/mod"><div className="caucus-option">Moderated Caucus</div></Link>
            <Link to="/unmod"><div className="caucus-option">Unmoderated Caucus</div></Link>
            <Link to="/speaker"><div className="caucus-option">Speaker's List</div></Link>
        </div>
    );
}

export default SelectCaucus;