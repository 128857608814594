import React from "react";

const TimerInput = ({name, state, handleChange}) => {

    //nameUpper is the name of the input field, but with the first letter capitalized
    const nameUpper = name.charAt(0).toUpperCase() + name.slice(1);

    const minName = `${name}Minutes`;
    const secName = `${name}Seconds`;

    return (
        <div className="">
            <label>{nameUpper} Time: </label>
            <input className="ml-5 rounded-sm" type="number" name={minName} min="0" max="60" value={state[minName]} onChange={handleChange} placeholder="00"/>
            <label>m : </label>
            <input className="rounded-sm" type="number" name={secName} min="0" max="90" value={state[secName]} onChange={handleChange} placeholder="00"/>
            <label>s</label>
            
        </div>
    )
};

export default TimerInput;