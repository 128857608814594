import React, { useEffect } from 'react';

const Timer = ({ time, setTime, active, setTimerActive }) => {
    
    useEffect(() => {
        const interval = setInterval(() => {
            if (active) {
                if (time > 0) {
                    setTime(time - 1);
                } else if(time === 1) {
                    setTime(time - 1);
                    setTimerActive(false);
                    clearInterval(interval);
                } else if(time <= 0) {
                    setTimerActive(false);
                    clearInterval(interval);
                }
            } else {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    });

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return (
        <div className="flex flex-col items-center pt-11 pb-5 text-5xl">
            Time remaining: <div className="text-5xl bg-gray-200 rounded-3xl py-4 w-2/5 px-2 my-2 "> {minutes < 10 ? `0${minutes}`:minutes}:{seconds < 10 ? `0${seconds}` : seconds}</div>
            <div className="text-3xl" >
                {active ? "Timer Running" : "Timer Paused"}
            </div>
        </div>
    )
};

export default Timer;