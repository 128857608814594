import React from "react";

const SubmitButton = ({onClick, validInput}) => {
    return (
        <button className={`py-2 px-3 rounded-lg transition-all 
            ${validInput ? "shadow-lg bg-gray-300 hover:bg-gray-400"  : "shadow-inner bg-gray-200"}`} 
            onClick={onClick} active={validInput}>
                Submit
        </button>
    )
}

export default SubmitButton;