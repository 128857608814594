import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import DefineSpeaker from './Views/Speaker/DefineSpeaker';
import DefinedSpeaker from './Views/Speaker/DefinedSpeaker';
import DefineMod from './Views/Mod/DefineMod';
import DefinedMod from './Views/Mod/DefinedMod';
import DefineUnmod from './Views/Unmod/DefineUnmod';
import DefinedUnmod from './Views/Unmod/DefinedUnmod';
import SelectCaucus from './Views/SelectCaucus';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <App>
      <Routes>
        <Route path="/" element={<SelectCaucus />} />
        <Route path="/mod" element={<DefineMod />} />
        <Route path="/mod/:totalTime/:speakingTime/:topic" element={<DefinedMod />} /> 
        <Route path="/unmod" element={<DefineUnmod />} />
        <Route path="/unmod/:totalTime" element={<DefinedUnmod />} />
        <Route path="/speaker" element={<DefineSpeaker />} />
        <Route path="/speaker/:speakingTime" element={<DefinedSpeaker />} />
      </Routes>
      </App>
    </BrowserRouter>
    {/* <App /> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
