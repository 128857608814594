import React from "react";

import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";

import Timer from "../../Components/Timer/Timer";
import CaucusPage from "../../Components/CaucusPage";
import DefineTitle from "../../Components/DefineTitle";

const DefinedMod = () => {

    const {totalTime, speakingTime, topic} = useParams();

    const totalSpeakers = totalTime/speakingTime;

    const [valid, setValid] = useState(true);

    const [remainingSpeakers, setRemainingSpeakers] = useState(totalSpeakers);
    const [remainingTime, setRemainingTime] = useState(speakingTime);

    const [timerActive, setTimerActive] = useState(false);
    

    const checkValidInput = () => {
        if(totalTime <= 0 || speakingTime <= 0 || topic === "") {
            setValid(false);
            return
        }
        if(totalTime % speakingTime !== 0) {
            setValid(false);
            return
        }
    }
    useEffect(() => {
        checkValidInput();
    });

    const resetTimer = (e) => {
        setRemainingTime(speakingTime);
        setTimerActive(false);
    }

    const nextSpeaker = (e) => {
        if(remainingSpeakers > 1) {
            setRemainingSpeakers(remainingSpeakers - 1);
            resetTimer(e);
        } 
    };

    const startStop = (e) => {
        if(remainingTime > 0) {
            setTimerActive(!timerActive);
        } else {
            nextSpeaker(e);
            setTimerActive(true);
        }
    };


    if(!valid) {
        return (
            <CaucusPage>
                <h1>Invalid Input</h1>
                <Link to="/mod">Go Back</Link>
                <Link to="/">New Caucus</Link>
            </CaucusPage>
        )
    } else {
        return (
            <CaucusPage>
                <DefineTitle title="Moderated Caucus" />
                <div className="w-3/4">
                    <h1 className="font-bold">{topic}</h1>
                    <div>
                        Speaker <span className=" font-bold">{totalSpeakers - remainingSpeakers + 1}</span> of {totalSpeakers}
                    </div>
                    <Timer time={remainingTime} setTime={setRemainingTime} active={timerActive} setTimerActive={setTimerActive} />
                    <div className="w-full flex flex-row justify-center space-x-6 pb-2 text-lg">
                        <button className="timer-control" onClick={startStop}>{timerActive ? "Pause" : "Start"} Timer</button>
                        <button className="timer-control" onClick={resetTimer}>Reset Timer</button>
                    </div>

                    <div className="w-full flex flex-row justify-center space-x-6 pb-2 text-lg">
                        
                        <button className="timer-control" onClick={nextSpeaker} active={remainingSpeakers>1}>Next Speaker</button>
                    </div>
                    <div className="w-full flex flex-row justify-center space-x-6 text-lg">
                        <button className="timer-control" onClick={(e) => {e.preventDefault()}}><Link to="/">New Caucus</Link></button>
                    </div>
                </div>
            </CaucusPage>
        )
    }
    
};

export default DefinedMod;