import React from "react";

const CaucusPage = (props) => {
    return (
        <div className=" h-[100%] w-full px-20 text-2xl text-center space-y-6 flex flex-col items-center" >
            {props.children}
        </div>
    );
};

export default CaucusPage;