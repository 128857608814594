import React from "react";
import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import TimerInput from "../../Components/TimerInput/TimerInput";
import DefineTitle from "../../Components/DefineTitle";
import InputForm from "../../Components/InputForm";
import SubmitButton from "../../Components/SubmitButton";
import CaucusLink from "../../Components/CaucusLink";

const DefineSpeaker = (props) => {

    const navigate = useNavigate();

    const convertTime = (minutes, seconds) => {
        return (parseInt(minutes) * 60) + parseInt(seconds);
    };

    const [validInput, setValidInput] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [state, setState] = useState({
        speakingMinutes: 0,
        speakingSeconds: 0,
    });

    const checkValidInput = () => {
        let speakingTime = convertTime(state.speakingMinutes, state.speakingSeconds);
        if(speakingTime <= 0) {
            setValidInput(false);
            setErrorMessage("Time must be positive integer");
            return
        }
        setValidInput(true);
        setErrorMessage("")
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState(prevstate => ({
            ...prevstate,
            [name]: value
        }));
    };

    useEffect(() => {
        checkValidInput();
        //console.log(state);
    })

    const convertToPath = () => {
        //Convert total minutes and total seconds to total time in seconds
        //Convert speaking minutes and speaking seconds to speaking time in seconds
        const speakingTime = convertTime(state.speakingMinutes, state.speakingSeconds);
        if(validInput) {
            return `/speaker/${speakingTime}`;
        } 

    }

    const handleClick = (e) => {
        e.preventDefault();
        if(validInput) {
            let path = convertToPath();
            navigate(path);
        }
    }

    return (
        <div className="app">
            <div>
                <DefineTitle title="Speaker's List (Round Robin)" />
            </div>
            <InputForm>
                <div>
                    <TimerInput name="speaking" state={state} handleChange={handleChange}/>
                </div>
                <div className="group relative">
                    {!validInput ? <div className="invisible group-hover:visible absolute 
                        -top-4 text-sm bg-gray-300 bg-opacity-70 rounded-lg px-2 py-1">
                            {errorMessage}
                    </div> : <></>}
                    <SubmitButton onClick={handleClick} validInput={validInput}/>
                </div>
            </InputForm>    
            
            <CaucusLink />
        </div>
    );
};

export default DefineSpeaker;