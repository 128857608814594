import React from "react";
import { Link } from "react-router-dom";

const CaucusLink = () => {
    return (
        <>
            <div className="h-4" />
            <Link className="mx-8 bg-gray-300 py-2 px-3 rounded-lg shadow-lg hover:bg-gray-400" to="/">New Caucus</Link>
        </>
        
    )
}

export default CaucusLink;